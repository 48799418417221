/***
*
*   LOGO
*   Replace the image in /images with your own logo
*
**********/

import { Link, ClassHelper } from 'components/lib';
import Style from './logo.tailwind.js';

export function Logo(props){

  const logoStyle = ClassHelper(Style, props);
  
  return(
    <div url='/' className={ logoStyle } onClick={() => {
      if(typeof props.onClick === 'function') {
        props.onClick()
      }
    }}>
      <p className='text text-slate-200'>Oasis</p>
      <p className='text-[10px] text-slate-200' >Construction</p>
    </div>
  )
}
