import { Fragment, useContext, useEffect, useState } from "react";
import {
  AuthContext,
  ViewContext,
  Card,
  Animate,
  Button,
  Select,
  DateFormat
} from "components/lib";
import axios from "axios";

const tables = [
  { value: 0, label: 'request' },
  { value: 1, label: 'act' }
]

export function Browser(props) {
  const viewContext = useContext(ViewContext);
  const authContext = useContext(AuthContext);
  const [data, setData] = useState([
    {
      key: "key",
      value: null,
    },
  ]);
  const [table, setTable] = useState(null);
  const [account_id, setAccountID] = useState(null);
  const [browser, setBrowser] = useState(null);

  const [result, setResult] = useState(null);

  useEffect(() => {
    getBrowserData();
  }, []);

  async function getBrowserData(data, callback) {
    await axios
      .get("/api/browser")
      .then((res) => {
        setBrowser(res.data);
      })
      .catch((e) => {
        console.log(e);
        viewContext.notification.show("Ошибка", "error", true);
      });
  }

  async function fetch(url) {
    if (!account_id)
      return viewContext.notification.show("Выберите аккаунт", "error", true);

    const params = data.reduce((sum, i) => {
      sum[i.key] = i.value;
      return sum;
    }, {});

    await axios({
      method: "get",
      url: url,
      params: {
        ...params,
        account_id: account_id,
      },
    })
      .then((res) => {
        setResult(res.data);
      })
      .catch((e) => {
        console.log(e);
        viewContext.notification.show(
          e?.response?.data?.message ?? "Ошибка",
          "error",
          true
        );
      });
  }

  return (
    <Animate>
      <Card loading={false}>
        <Select
          label="Аккаунт"
          name="name"
          placeholder="Выберите аккаунт"
          value={account_id}
          onChange={(a, v) => setAccountID(v)}
          options={browser?.accounts ?? []}
        />

        <Select
          label="Сущность"
          name="name"
          placeholder="Выберите таблицу"
          value={table}
          onChange={(a, v) => setTable(v)}
          options={tables}
        />

        {data &&
          data.map((item, i) => {
            return (
              <KeyValue
                i={i}
                data={item}
                remove={() => {
                  if (i === 0) return;
                  data.splice(i, 1);
                  setData([...data]);
                }}
                setData={(o) => {
                  data[i] = o;
                  setData([...data]);
                }}
              />
            );
          })}

        <Button
          text="Добавить"
          action={() => setData([...data, { key: "key", value: null }])}
          color="blue"
          className=" !px-2 !py-0 mb-5"
        />

        <Button action={() => fetch(`/api/request`)} text="Запрос" />

        <section>
          <BrowseRequest data={result}/>
        </section>
      </Card>
    </Animate>
  );
}

const KeyValue = ({ data, setData, i, remove }) => {
  return (
    <div className="flex gap-2 items-start mb-2 ">
      <input
        value={data.key}
        onChange={(e) =>
          setData({
            key: e.target.value,
            value: data.value,
          })
        }
        type="text"
        className="border min-w-[200px] px-2"
      />
      <textarea
        value={data.value}
        onChange={(e) =>
          setData({
            key: data.key,
            value: e.target.value,
          })
        }
        className="border min-w-[400px] px-2"
      />

      <Button
        text="x"
        action={() => remove()}
        color="red"
        className="text-xl !px-2 !py-0"
      />
    </div>
  );
};

const BrowseRequest = (data) => {
  if(!data?.data) return;

  const { 
    users, counterparties,
    request, request_items, order_items,
    request_children, request_signs,
    request_payments, units
  } = data.data;
  
  const requestFields = [
    "id",
    "request_type",
    "status_id",
    "project_id",
    "parent_id",
    "is_goods_accepted",
    "is_performed",
    "is_storable",
    "is_finished",
    "req_status",
    "resp_id",
    "source",
    "number",
    "file_accepted",
    "created_at"
  ]

  const requestItemFields = [
    "id",
    "resource_id",
    "request_id",
    "needed",
    "ordered",
    "delivered",
    "request_category_id",
    "resource_type",
    "source",
    "unit_id",
    "is_goods_accepted",
    "is_ordered",
    "note",
    "position",
    "is_works_accepted",
    "producable",
    "produced",
    "can_edit_producable",
    "deadline_at",
    "created_at",
    "updated_at",
    "deleted_at"
  ]

  const orderItemFields = [
    "id",
    "resource_id",
    "resource_type",
    "amount",
    "status_id",
    "counterparty_id",
    "request_item_id",
    "request_id",
    "price",
    "file_id",
    "file_accepted",
    "nr",
    "download",
  ]

  const requestSignFields = [
    "id",
    "model_id",
    "name",
    "level",
    "is_main",
    "target_id",
    "user_id",
    "order",
    "level_name",
    "type",
    "can_reset",
    "sign_status_id",
    "sign_status.status",
    "sign_status_reason",
    "sign_status_updated_at"
  ]

  const requestPaymentFields = [
    "id",
    "project_id",
    "counterparty_id",
    "sum",
    "paid",
    "to_pay",
    "status",
    "created_at",
    "request_item_ids",
    "order_item_ids",
    "file_ids",
    "nr"
  ]

  return (
    <div>
      <p className="text-lg font-bold mt-3">request</p>
      <TableComponent 
        arr={[request]} 
        fields={requestFields} 
        users={users} 
        counterparties={counterparties}
        units={units}
      />

      <p className="text-lg font-bold">request_items</p>
      <TableComponent 
        arr={request_items} 
        fields={requestItemFields} 
        users={users} 
        counterparties={counterparties}
        units={units}
      />

      <p className="text-lg font-bold">order_items</p>
      <TableComponent 
        arr={order_items} 
        fields={orderItemFields}
        users={users} 
        counterparties={counterparties}
        units={units}
      />

      <p className="text-lg font-bold">request_payments</p>
      <TableComponent 
        arr={request_payments} 
        fields={requestPaymentFields} 
        users={users} 
        counterparties={counterparties}
        units={units}
      />

      <p className="text-lg font-bold">request_signs</p>
      <TableComponent 
        arr={request_signs} 
        fields={requestSignFields} 
        users={users} 
        counterparties={counterparties}
        units={units}
      />

      <div className="flex flex-col">
        {request_children.map((child, key) => (
          <div key={key} className="border border-slate-400 px-2 py-2 rounded-md  mt-10">
            <p className="text-lg font-bold">request_children #{key + 1}</p>
            <TableComponent 
              arr={[child]} 
              fields={requestFields} 
              users={users} 
              counterparties={counterparties}
              units={units}
            />

            <p className="text-lg font-bold">request_children_items #{key + 1}</p>
            <TableComponent 
              arr={child.items} 
              fields={requestItemFields} 
              users={users} 
              counterparties={counterparties}
              units={units}
            />

            <p className="text-lg font-bold">request_children_orders #{key + 1}</p>
            <TableComponent 
              arr={child.orders} 
              fields={orderItemFields} 
              users={users} 
              counterparties={counterparties}
              units={units}
            />

            <p className="text-lg font-bold">request_children_payments #{key + 1}</p>
            <TableComponent 
              arr={child.payments} 
              fields={requestPaymentFields} 
              users={users} 
              counterparties={counterparties}
              units={units}
            />

            <p className="text-lg font-bold">request_children_signs #{key + 1}</p>
            <TableComponent 
              arr={child.signs} 
              fields={requestSignFields} 
              users={users} 
              counterparties={counterparties}
              units={units}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const TableComponent = ({ arr, fields, users, counterparties, units }) => {
  const getName = (column, item, users) => {
    switch (column) {
      case 'resp_id':
        return <label>{users.find(user => user.id === item[column])?.name || 'Unknown'}, {item[column]}</label>
      case 'user_id':
        return <label>{users.find(user => user.id === item[column])?.name || 'Unknown'}, {item[column]}</label>
      case 'counterparty_id':
        return <label>{counterparties.find(counterparty => counterparty.id === item[column])?.name || 'Unknown'}, {item[column]}</label>
      case 'created_at':
        return DateFormat(item[column], 'H:i d.m.Y')
      case 'updated_at':
        return DateFormat(item[column], 'H:i d.m.Y')
      case 'deleted_at':
        return DateFormat(item[column], 'H:i d.m.Y')
      case 'sign_status_updated_at':
        return DateFormat(item[column], 'H:i d.m.Y')
      case 'download':
        return (
          <a
            download={true}
            target="_blank"
            className="bg-sky-400 px-1 text-white rounded-sm"
            href={'https://hitech-oasis.com' + item.path + item.name}
          >
            Скачать
          </a>
        )
      default:
        return item[column] === null ? 'null' : item[column];
    }
  };

  return (
    <>
      {arr.length > 0 ? (
        <div className="table-container">
          <table className="table-browser">
            <tr>
              {fields.map(column => (
                <th key={column}>{column}</th>
              ))}
            </tr>
            {arr.map((item, index) => (
              <tr key={index}>
                {fields.map(column => (
                  <>
                    <td 
                      key={column} 
                      className={item[column] === null ? "text-sky-400" : ""}
                    >
                    {getName(column, item, users)}                      
                    </td>
                  </>
                ))}
              </tr>
            ))}
          </table>
        </div>
      ) : (
        <>Отсутствует</>
      )}
    </>
  );
};
