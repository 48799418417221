/***
*
*   APP LAYOUT
*   The mission control dashboard layout containing the navigation
*   and header (title, secondary nav)
*
*   PROPS
*   title: title of the view
*
**********/

import { Fragment, useContext } from 'react';
import { AuthContext, AppNav, Header } from 'components/lib';
import Style from './app.module.scss';

export function AppLayout(props){

  // context & style
  const context = useContext(AuthContext);

  return (
    <Fragment>
      <AppNav
        type='popup'
        items={[
          { label: 'Дэшборд', icon: 'activity', link: '/dashboard' },
          // { label: 'Отзывы', icon: 'heart', link: '/feedback' },
          { label: 'Логи', icon: 'clipboard', link: '/logs' },
          { label: 'Наблюдатель', icon: 'eye', link: '/browser' },
          { label: 'События', icon: 'clock', link: '/events' },
          { label: 'Аккаунты', icon: 'credit-card', link: '/accounts' },
          { label: 'Юзеры', icon: 'users', link: '/users' },
          { label: 'Выйти', icon: 'log-out', action: context.signout }
        ]}
      />

      <main className={ Style.app }>

        <Header title={ props.title } />
        { <props.children {...props.data }/> }

      </main>
    </Fragment>
  );
}
