import { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import Settings from 'settings';
import { ViewContext, Card, Table, Animate, useAPI, Select } from 'components/lib';

export function Users(props){

  const viewContext = useContext(ViewContext);
  const usersreq = useAPI('/api/user');
  const [users, setUsers] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const accounts = useAPI('/api/account');
  const [account, setAccount] = useState(null);

  useEffect(() => {
    if (usersreq.data){
      setUsers(usersreq.data);
      setFiltered(usersreq.data);
    }
  }, [usersreq.data]);

  useEffect(() => {
    let u = users;
    if (account){
      u = users.filter(u => u.default_account === account);
    }
    setFiltered([...u]);
  }, [account]);

  function editUser(data, callback){    
    viewContext.modal.show({

      title: 'Edit User',
      form: {
        name: {
          label: 'Name',
          type: 'text',
          value: data.name,
          required: true,
        },
        email: {
          label: 'Email',
          type: 'email',
          value: data.email,
          required: true
        }
      },
      buttonText: 'Save',
      url: `/api/user/${data.id}`,
      method: 'PATCH'

    }, (res) => {

      viewContext.notification.show(data.name + ' was updated', 'success', true);
      callback(res);

    });
  }

  function deleteUser(data, callback){
    viewContext.modal.show({
      title: 'Delete User',
      form: {},
      buttonText: 'Delete User',
      text: `Are you sure you want to delete ${data.email}? This will remove them from all accounts.`,
      url: `/api/user/${data.id}`,
      method: 'DELETE',
      destructive: true,

    }, () => {

      viewContext.notification.show(`${data.email} was deleted`, 'success', true);
      callback();

    });
  }

  async function impersonateUser(data){

    try {

      const res = await Axios.post(`/api/user/impersonate/${data.id}`);
      const token = res.data?.data?.token;
      if (token){

        let location = Settings[process.env.NODE_ENV].remote_client + `/signin/impersonate?token=${token}`
        window.open(location, "_blank");

      }
    }
    catch (err){

      viewContext.handleError(err);

    }
  }

  return(
    <Animate>
      <Card>
        <Select 
          label="Аккаунт"
          name="account"
          placeholder="Выберите аккаунт"
          value={ account }
          onChange={ (a, v) => setAccount(v) }
          options={ accounts.data }
        />
      </Card>
      <Card>
        <Table
          data={{
            body: filtered,
            header: [
              {title: 'Имя', name:'name', sort: true},
              {title: 'Должность', name:'position', sort: true},
              {title: 'Аккаунт', name:'account'},
              {title: ''},
            ]
          } }
          hide={['id']}
          loading={ usersreq.loading }
          // show={['name', 'position', 'last_active', 'email', ]}
          show={['name', 'position', 'account' ]}
          actions={{
            
            edit: editUser, 
            // delete: deleteUser, 
            // email: true,
            custom: [{ icon: 'log-in', action: impersonateUser }]

          }}
        />
      </Card>
    </Animate>
  );
}
